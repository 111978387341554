(function(w, d, undefined) {

	"use strict";

	var init = function() {
    mainNav.init();
    videoPlayer.init();
    // [].forEach.call(d.querySelectorAll('a[href^="http"], a[href^="//"]'), function($link) {
    //   if ($link.href.indexOf(w.location.hostname) === -1 && !$link.getAttribute('target')) {
    //     $link.setAttribute('target', '_blank');
    //   }
    // });
  };

	d.addEventListener('DOMContentLoaded', init);

}(window, window.document));
