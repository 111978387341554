var mainNav = (function(w, d, undefined) {

  'use strict';

  var s = {
        selectors: {
          theMenuTrigger: '.header__trigger'
        },
        classes: {
          menuIsVisible: 'menu-is-visible'
        }
      },
      els = {},
      init = function() {

        // define elements
        els.theBody = d.body;
        els.theMenuTrigger = d.querySelector(s.selectors.theMenuTrigger);

        // define settings
        s.breakPoint = w.getComputedStyle(d.body, ':before').getPropertyValue('content');

        // no elements
        if(!els.theMenuTrigger) { return; }

        // bind events
        els.theMenuTrigger.addEventListener('click', toggleMenu);

      },
      toggleMenu = function() {



        // toggle visible class
        els.theBody.classList.contains(s.classes.menuIsVisible) ? hideMenu() : showMenu();

      },
      showMenu = function() {

        // add visible class to the body
        els.theBody.classList.add(s.classes.menuIsVisible);

      },
      hideMenu = function() {

        // remove visible class from the body
        els.theBody.classList.remove(s.classes.menuIsVisible);

      };

  return {
    init: init
  };

}(window, window.document));
