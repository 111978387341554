var videoPlayer = (function(w, d, undefined) {

	'use strict';

	var s = {
			selectors: {
				theBucket: '#theBucket',
				theWrap: '.videoPlayer',
				theID: '.videoID',
				theTriggerPlay: '[data-video]',
				theTriggerClose: '.videoPlayer__closeTrigger'
			},
			classes: {
				theWrap: 'videoPlayer',
				theActiveClass: 'videoPlayer-is-active'
			},
			delay: 700
		},
		els = {},
		init = function() {

			// define elements
			els.theBucket = d.querySelector(s.selectors.theBucket) || d.body;
			els.theID = d.querySelectorAll(s.selectors.theID);

			// do stuff on init
			Array.prototype.slice.call(els.theID).forEach(function(el) {

				el.parentNode.dataset.video = el.textContent;

			});

			// define all triggers
			els.theTriggerPlay = d.querySelectorAll(s.selectors.theTriggerPlay);

			// no elements
			if(!els.theTriggerPlay.length) { return; }

			// bind events
			Array.prototype.slice.call(els.theTriggerPlay).forEach(function(theTriggerPlay) {

				theTriggerPlay.addEventListener('click', function(e) { e.preventDefault(); openIt(this); });

			});

		},
		bindIt = function() {

			// define elements
			els.theWrap = d.querySelector(s.selectors.theWrap);
			els.theTriggerClose = d.querySelectorAll(s.selectors.theTriggerClose);

			// bind events
			Array.prototype.slice.call(els.theTriggerClose).forEach(function(theTriggerClose) {

				theTriggerClose.addEventListener('click', function(e) { e.preventDefault(); closeIt(); });

			});

		},
		buildIt = function(theID) {

			// define variables
			var theHTML, theContent;

			// no param
			if(!theID) { return false; }

			// define the HTML
			theHTML = d.createElement('div');
			theHTML.classList.add(s.classes.theWrap);
			theContent = '';
			theContent += '<div class="videoPlayer__overlay videoPlayer__closeTrigger"></div><div class="videoPlayer__inner"><div class="videoPlayer__content"><div class="videoPlayer__action"><span class="videoPlayer__action__label videoPlayer__closeTrigger">Close</span><span class="videoPlayer__symbol videoPlayer__closeTrigger"><svg class="symbol symbol--close" viewBox="0 0 12 14" version="1" xmlns="http://www.w3.org/2000/svg"><path d="M6.066 5.492L2.372.931A1 1 0 1 0 .818 2.19l3.848 4.752L.418 11.34a1 1 0 0 0 1.439 1.39v-.001l4.077-4.222 3.694 4.561a1 1 0 1 0 1.554-1.259L7.334 7.059l4.248-4.399a1 1 0 0 0-1.439-1.39v.001L6.066 5.492z" fill="currentColor" fill-rule="evenodd"/></svg></span></div>';
			theContent += '<iframe class="videoPlayer__player" src="https://player.vimeo.com/video/' + theID + '?&autoplay=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div></div>';

			theHTML.innerHTML = theContent;

			return theHTML;

		},
		openIt = function(el) {

			// define variables
			var theHTML;

			// no param or data attr
			if(!el || !el.dataset.video) { return; }

			// build the HTML
			theHTML = buildIt(el.dataset.video);

			// if build succeeded
			if(theHTML) {

				// append it to the body
				els.theBucket.appendChild(theHTML);

				// bind key event for ESC key trigger
				d.addEventListener('keyup', function(e) { keyTabbed(e.key); });

				// bind elements and their events
				bindIt();

				// add class to the body
				setTimeout(function() { d.body.classList.add(s.classes.theActiveClass); }, 100);

			}

		},
		closeIt = function() {

			// define variables
			var theParent;

			// define the parent
			theParent = els.theWrap.parentNode;

			// unbind key event for ESC key trigger
			d.removeEventListener('keyup', function(e) { keyTabbed(e.key); });

			// remove class from the body
			d.body.classList.remove(s.classes.theActiveClass);

			// remove the videoPlayer
			setTimeout(function() { theParent && theParent.removeChild(els.theWrap); }, s.delay);

		},
		keyTabbed = function(theKey) {

			// no param
			if(!theKey) { return; }

			// if is escape key close the video overlay
			theKey === 'Escape' && closeIt();

		};

	return {
    init: init
  };

}(window, window.document));
